<template>
    <div>
        <div class="mt-3 ml-1 vx-row">
            <div class="mb-6 vx-row" style="width:70%">
                <div class="flex items-center w-full vx-col sm:w-1/4">
                    <span>Warehouse Code</span>
                </div>
                <div class="w-full vx-col sm:w-3/4">
                    <CustomMSelect :value="selectedSrcWarehouses" base-url="/api/wms/v1/wms/inbound/receive/purchase-order/receive/warehouses" 
                        label="name" 
                        track-by="name" 
                        :multiple="true" 
                        :allowEmpty="true"
                        @selected="(val) => {
                            localStore('selectedSrcWarehouses', JSON.stringify(val))
                            this.selectedSrcWarehouses = val
                            renderPOCode=false
                            $nextTick(()=>{
                                renderPOCode=true
                            })
                        }">
                    </CustomMSelect>
                </div>
            </div>
            <div class="mb-6 vx-row" style="width:70%">
                <div class="flex items-center w-full vx-col sm:w-1/4">
                    <span>Supplier</span>
                </div>
                <div class="w-full vx-col sm:w-3/4">
                    <CustomMSelect :value="selectedSuppliers" base-url="/api/wms/v1/master/suppliers" 
                        label="codename" 
                        track-by="codename" 
                        :multiple="true" 
                        :allowEmpty="true"
                        @selected="(val) => {
                            localStore('selectedSuppliers', JSON.stringify(val))
                            this.selectedSuppliers = val
                            renderPOCode=false
                            $nextTick(()=>{
                                renderPOCode=true
                            })
                        }">
                    </CustomMSelect>
                </div>
            </div>
            <!-- <div class="mb-6 vx-row" style="width:70%">
                <div class="flex items-center w-full vx-col sm:w-1/4">
                    <span>ETA</span>
                </div>
                <div class="w-full vx-col sm:w-3/4">
                    <DateRangePicker @rangeValue="(val)=>{
                        this.rangeDateValue = val
                    }"/>
                </div>
            </div> -->
            <div class="mb-6 vx-row" style="width:70%">
                <div class="flex items-center w-full vx-col sm:w-1/4">
                    <span>Purchase Order Codes</span>
                </div>
                <div class="w-full vx-col sm:w-3/4">
                    <CustomMSelect v-if="renderPOCode" 
                        :value="selectedPoCodes"
                        :status="this.activeTab" 
                        :multiple="true" 
                        :allowEmpty="true"
                        :base-url="`api/wms/v1/wms/inbound/receive/purchase-order/receive/select-options?warehouse_ids=${this.selectedWhIDs}&supplier_ids=${this.selectedSupplierIDs}`" 
                        label="code" 
                        track-by="code"
                        @selected="(val) => {
                            localStore('selectedPoCodesPutaway', JSON.stringify(val))
                            this.selectedPoCodes = val
                        }">
                    </CustomMSelect>
                </div>
            </div>
            <div class="mb-6 vx-row" style="width:70%">
                <div class="flex items-center w-full vx-col sm:w-1/4">
                </div>
                <div class="w-full vx-col sm:w-3/4">
                    <vs-button color="primary" @click="handleFilter()">Filter</vs-button>
                </div>
            </div>
        </div>
        <div class="mt-3 vx-row">
            <div class="w-full vx-col mb-base">
                <Table :draw="draw"  
                    :status="this.activeTab"
                    :source-warehouse-ids="this.selectedWhIDs"
                    :supplier-ids="this.selectedSupplierIDs" 
                    :po-ids="this.selectedPoIDs">
                </Table>
            </div>
        </div>
    </div>
</template>

<script>
import CustomMSelect from "./CustomMultiSelect.vue";
import Datepicker from "vuejs-datepicker";
import DateRangePicker from "@/components/DateRangePicker.vue";
// import moment from "moment";
import Table from "./TablePutAway.vue";
export default {
    components: {
        CustomMSelect,
        Datepicker,
        DateRangePicker,
        Table
    },
    mounted() {
        this.selectedSrcWarehouses = JSON.parse(localStorage.getItem('selectedSrcWarehouses'))
        this.selectedSuppliers = JSON.parse(localStorage.getItem('selectedSuppliers'))
        this.selectedPoCodes = JSON.parse(localStorage.getItem('selectedPoCodesPutaway'))

        let a = {
            id: 0,
            code: "All",
            name: "All"
        }
        if (this.selectedSrcWarehouses == null) {
            this.selectedSrcWarehouses = [a]
        }

        if (this.selectedSuppliers == null) {
            this.selectedSuppliers = [a]
        }

        if (this.selectedPoCodes == null) {
            this.selectedPoCodes = [a]
        }
    },
    data() {
        return {
            activeTab: "ConfirmedCheck",
            draw: 0,
            renderPOCode:true,
            selectedSrcWarehouses: [],
            selectedSuppliers: [],
            selectedPoCodes: [],
            selectedWhIDs: "0",
            selectedSupplierIDs: "0",
            selectedPoIDs: "0",
        }
    },
    methods: {
        handleFilter() {
            this.draw++;
        },
        localStore(key,val){
            localStorage.setItem(key, val)
        },
        handleCreate() {
            this.$router.push({
                name: this.$store.state.inbound.purchaseOrder.baseRouterName + ".create-put-away",
            });
        },
    },
    watch: {
        selectedSrcWarehouses(v) {
            var sourceWhIds = []
            if (Array.isArray(v)) {
                v.forEach(e => { if (e.id) sourceWhIds.push(e.id) });
            }
            this.selectedWhIDs = sourceWhIds.join(",")
        },
        selectedSuppliers(v) {
            var supplierIds = []
            if (Array.isArray(v)) {
                v.forEach(e => { if (e.id) supplierIds.push(e.id) });
            }
            this.selectedSupplierIDs = supplierIds.join(",")
        }, 
        selectedPoCodes(v) {
            var poCodeIds = []
            if (Array.isArray(v)) {
                v.forEach(e => { if (e.id) poCodeIds.push(e.id) });
            }
            this.selectedPoIDs = poCodeIds.join(",")
        }
    },
}
</script>

<style>
.vdp-datepicker__clear-button {
    position: absolute;
    float: right;
    right: 18px;
    font-size: 2em;
    color: red;
}
</style>